/* eslint-disable no-unused-vars */
/* eslint-disable capitalized-comments */
import React, { useState, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import FallbackUI from "./../error_boundary/FallbackUI";
import { Routes, Route } from "react-router-dom";
import Home from "./../../pages/home/Home";
import Login from "../../pages/login/Login";
import Signup from "../../pages/signup/Signup";
import Plan from "../../pages/plan/Plan";
import ResetRequest from "../../pages/reset_request/ResetRequest";
import ResetPassword from "../../pages/reset_password/ResetPassword";
import NotFound from "../../pages/not_found/NotFound";
import { UserProvider } from "./../../context/UserContext";
import Dashboard from "./../../pages/dashboard/Dashboard";
import DashboardHome from "../../pages/dashboard/dashboard_home/DashboardHome";
import LocalDiscount from "../../pages/local_discount/LocalDiscount";
import MyDiscount from "../../pages/local_discount/my_discount/MyDiscount";
import FeaturedDiscount from "../../pages/local_discount/featured_discount/FeaturedDiscount";
import FavoriteDiscount from "../../pages/local_discount/favorite_discount/FavoriteDiscount";
import AllDiscount from "../../pages/local_discount/all_discount/AllDiscount";
import Member from "../../pages/neighborhood/member/Member";
import Settings from "../../pages/settings/Settings";
import Profile from "../../pages/settings/profile/Profile";
import Account from "../../pages/settings/account/Account";
import Privacy from "../../pages/settings/privacy/Privacy";
import EmailPreferences from "../../pages/settings/email_preferences/EmailPreferences";
import Subscription from "../../pages/settings/subscription/Subscription";
import Activity from "../../pages/settings/activity/Activity";
import ScholarshipApps from "../../pages/settings/scholarship_apps/ScholarshipApps";
import Listings from "../../pages/marketplace_home/listings/Listings";
import Listing from "../../pages/marketplace_home/listing/Listing";
import Events from "../../pages/marketplace/marketplace_feed/events/Events";
// import Pricing from "../../pages/pricing/Pricing";
import ContactUs from "../../pages/contact_us/ContactUs";
import Search from "../../pages/search/Search";
import RequiresSubscription from "./../Protect/RequireSubscription";
import AuthUser from "../Protect/AuthUser";
import PersistAuth from "../Protect/PersistAuth";
import NoAuth from "../Protect/NoAuth";
import Cancel from "../../pages/subscription_summary/cancel";
import EmailVerification from "../../pages/verifications/EmailVerification";
import InactiveSubscription from "../Protect/InactiveSubscription";
import SubscriptionInfo from "../../pages/subscription_summary/subscription_info";
import Feature from "../Protect/Feature";
import { ToastContainer } from "react-toastify";
import Messaging from "../../pages/messaging/Messaging";
import Notification from "../../pages/notification/Notification";
import { NotificationProvider } from "../../context/NotificationContext";
import { ConversationProvider } from "../../context/ConversationContext";
import Policy from "../../pages/policies/Policy";
import GeneralTerms from "../../pages/policies/general_terms/GeneralTerms";
import TermsOfSale from "../../pages/policies/terms_of_sale/TermsOfSale";
import ElectronicCommunication from "../../pages/policies/electronic_communication/ElectronicCommunication";
import AntiDiscrimination from "../../pages/policies/anti_discrimination/AntiDiscrimination";
import COhatchPolicy from "../../pages/policies/cohatch_policy/COhatchPrivacy";
import Workshop from "../../pages/workshops/Workshop";
import WorkshopDetails from "../../pages/workshops/workshop_details/WorkshopDetails";
import WorkshopDetailsEventsAttending from "../../pages/workshops/workshop_details/WorkshopDetailsEventsAttending";
import MyWorkshops from "../../pages/workshops/my_workshops/MyWorkshops";
import EventsCalendar from "../../pages/workshops/events_calendar/EventsCalendar";
import WorkshopRsvp from "../../pages/workshops/workshop_rsvp/WorkshopRsvp";

//MARKETPLACE IMPORTS
import MarketPlace from "../../pages/marketplace/MarketPlace";
import MarketplaceFeed from "../../pages/marketplace/marketplace_feed/MarketplaceFeed";
import MyListing from "../../pages/marketplace/my_listing/MyListing";
import ListingAnalytics from "../../pages/marketplace/listing_analytics/ListingAnalytics";
import FavoriteListing from "../../pages/marketplace/favorite_listing/FavoriteListing";
import MarketplaceDirectory from "../../pages/marketplace/marketplaceDirectory/MarketplaceDirectory";
import LocalShopping from "../../pages/marketplace/marketplace_feed/local_shopping/LocalShopping";
import Services from "../../pages/marketplace/marketplace_feed/marketplace_services/Services";
import LocalFood from "../../pages/marketplace/marketplace_feed/local_food/LocalFood";
import CommunityResources from "../../pages/marketplace/marketplace_feed/community_resources/CommunityResources";

//Impact Routes
import Philanthropy from "../../pages/philanthropy/PhilanthropyDashboard";
import MemberEvents from "../../pages/philanthropy/member_events/MemberEvents";
import AddNonprofit from "../../pages/philanthropy/nonprofits/add_nonprofit/AddNonprofit";
import StartupClub from "../../pages/startup_club/Startup";
import InvestorClub from "../../pages/investor_club/Investor";
import Post from "../../pages/post/Post";
import ProfileDetail from "../../pages/profile/ProfileDetails";
import UnsubscribeList from "../../pages/unsubscribe/UnsubscribeList";
import PhilanthropyFeed from "../../pages/philanthropy/philanthropy_feed/PhilanthropyFeed";
import Nonprofits from "../../pages/philanthropy/nonprofits/nonprofit_page/Nonprofits";
import AllNonprofits from "../../pages/philanthropy/nonprofits/nonprofit_page/AllNonprofits";
import AddEvent from "../../pages/philanthropy/member_events/AddEvent";
import Scholarships from "../../pages/philanthropy/give_scholarships/Scholarships";
import GiveScholarship from "../../pages/philanthropy/give_scholarships/GiveScholarship";
import Drives from "../../pages/philanthropy/drives/Drives";
import NonprofitPage from "../../pages/philanthropy/nonprofits/nonprofit_page/NonprofitPage";
import DriveListing from "../../pages/philanthropy/drives/DriveListing";
import Volunteer from "../../pages/philanthropy/volunteer/Volunteer";
import MyEvents from "../../pages/philanthropy/volunteer/MyEvents";
import UpcomingVolunteer from "../../pages/philanthropy/volunteer/UpcomingVolunteer";
import Attending from "../../pages/philanthropy/volunteer/Attending";
import Impact from "../../pages/philanthropy/my_impact/Impact";
import FavoriteListings from "../../pages/philanthropy/my_impact/FavoriteListings";
import ImpactTimeLine from "../../pages/philanthropy/my_impact/ImpactTimeline";
import Overview from "../../pages/philanthropy/my_impact/Overview";
import ImpactFeed from "../../pages/philanthropy/my_impact/ImpactFeed";
import EventDetails from "../../pages/philanthropy/event_details/EventDetails";
import MyDrives from "../../pages/philanthropy/drives/MyDrives";
import MainDrives from "../../pages/philanthropy/drives/MainDrives";
import FavoriteDrives from "../../pages/philanthropy/drives/FavoriteDrives";
import ImpactStoryTimeline from "../../pages/philanthropy/impact_stories/ImpactStoryTimeline";
import ImpactSurvey from "../../pages/philanthropy/impact_stories/ImpactSurvey";
import StoryPage from "../../pages/philanthropy/impact_stories/StoryPage";
import MyNonprofits from "../../pages/philanthropy/nonprofits/my_nonprofits/MyNonprofits";
import BusinessHubHome from "../../pages/business_hub/BusinessHubHome";
import BusinessHub from "../../pages/business_hub/BusinessHub";
import MyApplications from "../../pages/business_hub/MyApplications";
import ScholarshipPrompt from "../../pages/philanthropy/nonprofits/add_nonprofit/ScholarshipPrompt";
// import HealthBenefits from "../../pages/health_and_wellness/health_benefits";
import ApplicationFile from "../../pages/business_hub/ApplicationFile";
import ScholarshipApplyPage from "../../pages/business_hub/ScholarshipApplyPage";
import ScholarshipApplySUPage from "../../pages/business_hub/ScholarshipApplySUPage";
import ScholarshipLogin from "../../pages/scholarshiplogin/ScholarshipLogin";
import ScholarshipSignup from "../../pages/scholarshiplogin/ScholarshipSignup";
import ApplicationSuccess from "../../pages/business_hub/ApplicationSuccess";
import ReapplicationPage from "../../pages/business_hub/ReapplicationPage";
import CommunityPartnerForm from "../../pages/community_partner/CommunityPartnerForm";
import GiveScholarshipLandingPage from "../app_terms_landing_page/GiveScholarshipLandingPage";
import BoostScholarshipLandingPage from "../app_terms_landing_page/BoostScholarshipLandingPage";

//Health page
// import HealthAndWellness from "../../pages/health_and_wellness/HealthAndWellness";

//COhatch Documents
import MEAgreementDoc from "../../pages/cohatch_documents/MEAgreement";
import CohatchDocuments from "../../pages/cohatch_documents/COhatchDocuments";
import CCAuthorizationDoc from "../../pages/cohatch_documents/CCAuthorizationDoc";
import COhatchMembershipTermsDoc from "../../pages/cohatch_documents/COhatchMembershipTermsDoc";
import AccountDoc from "../../pages/settings/SettingsDocsignee";

function App() {
	return (
		<>
			<Routes>
				<Route element={<UserProvider />}>
					{/**Should be accessed by all users regardless of their permissions and state*/}
					<Route element={
						<ErrorBoundary
							FallbackComponent={FallbackUI}
							onReset={() => window.location.reload()}
						>
							<PersistAuth />
						</ErrorBoundary>
					}
					>
						<Route path="/" element={<Home />} />
						<Route path="/unsubscribe" element={<UnsubscribeList />} />
						{/* <Route path="/pricing" element={<Pricing />} /> */}
						<Route path="/communitypartnerform" element={<CommunityPartnerForm />} />
						<Route path="/contact_us" element={<ContactUs />} />
						<Route path="events" element={<Events />} />
						<Route path="email_verification" element={<EmailVerification />} />
						<Route path="/policy" element={<Policy />} />
						<Route path="/general_terms" element={<GeneralTerms />} />
						<Route path="/terms_of_sale" element={<TermsOfSale />} />
						<Route path="/cohatch_policy" element={<COhatchPolicy />} />
						<Route path="/anti_discrimination" element={<AntiDiscrimination />} />
						<Route path="/electronic_communication" element={<ElectronicCommunication />} />
						<Route path="workshop_details/:eventId" element={<WorkshopDetails />} />
						<Route path="workshop_details/:eventId" element={<WorkshopDetailsEventsAttending />} />

						<Route path="/event_details/:eventid" element={<EventDetails />} />

						{/**Should be accessed by users who are or creating their account*/}
						<Route element={<RequiresSubscription />}>
							<Route path="plan" element={<Plan />} />
							<Route path="cancel_payment" element={<Cancel />} />
						</Route>

						<Route element={<InactiveSubscription />}>
							<Route path="/subscription_info" element={<SubscriptionInfo />} />
						</Route>

						{/**Should be accessed by users who are authenticated and have a state of either requires_email verification or active */}
						<Route element={<AuthUser />}>
							<Route element={<NotificationProvider />}>
								<Route element={<ConversationProvider />}>
									<Route element={<Feature excludedPlans={["DOCSIGNEE"]} />}>
										<Route path="/dashboard" element={<Dashboard />}>
											<Route index element={<DashboardHome />} />
											<Route path="local_discount" element={<LocalDiscount />}>
												<Route>
													<Route index element={<AllDiscount />} />
													<Route path="featured_discount" element={<FeaturedDiscount />} />
													<Route path="favorite_discount" element={<FavoriteDiscount />} />
												</Route>
												<Route path="my_discount" element={<MyDiscount />} />
											</Route>
											<Route path="marketplace" element={<MarketPlace />}>
												<Route index element={<MarketplaceFeed />} />
												<Route path="services" element={<Services />} />
												<Route path="local_shopping" element={<LocalShopping />} />
												<Route path="local_food" element={<LocalFood />} />
												<Route path="community_resources" element={<CommunityResources />} />
												<Route path="events" element={<Events />} />
												<Route path="services/listings/:category" element={<Listings />} />
												<Route path="local_shopping/listings/:category" element={<Listings />} />
												<Route path="local_food/listings/:category" element={<Listings />} />
												<Route path="community_resources/listings/:category" element={<Listings />} />
												<Route path="listing/:listingId" element={<Listing />} />
												<Route path="members" element={<Member />} />
												<Route path="local_discount" element={<LocalDiscount />}>
													<Route index element={<AllDiscount />} />
													<Route path="favorite_discount" element={<FavoriteDiscount />} />
													<Route path="my_discount" element={<MyDiscount />} />
												</Route>
												<Route path="my_listing" element={<MyListing />} />
												<Route path="listing_analytics" element={<ListingAnalytics />} />
												<Route path="favorite_listing" element={<FavoriteListing />} />
												<Route path="marketplace_directory" element={<MarketplaceDirectory />} />
											</Route>

											<Route path="workshop" element={<Workshop />}>
												<Route index element={<EventsCalendar />} />
												<Route path="my_workshops" element={<MyWorkshops />} />
												<Route path="workshop_rsvp" element={<WorkshopRsvp />} />
											</Route>

											<Route path="drives/drives_listing" element={<DriveListing />} />

											<Route path="addevent" element={<AddEvent />} />
											<Route path="business_hub" element={<BusinessHub />}>
												<Route index element={<BusinessHubHome />} />
												<Route path="my_applications" element={<MyApplications />} />
												<Route path="reapplication" element={<ReapplicationPage />} />
												<Route path="my_listings" element={<MyListing />} />
											</Route>
											<Route path="startup_club" element={<StartupClub />}> </Route>
											<Route path="investor_club" element={<InvestorClub />}> </Route>

											<Route path="settings" element={<Settings />}>
												<Route index element={<Profile />} />
												<Route path="account" element={<Account />} />
												<Route path="privacy" element={<Privacy />} />
												<Route path="email_preference" element={<EmailPreferences />} />
												<Route path="subscription" element={<Subscription />} />
												<Route path="activity" element={<Activity />} />
												<Route path="scholarship_apps" element={<ScholarshipApps />} />
											</Route>

											<Route path="messaging" element={<Messaging />} />
											<Route path="messaging/:conversationId" element={<Messaging />} />

											{/*Routes for Philanthropy*/}

											<Route path="volunteer" element={<Philanthropy />}>
												<Route path="nonprofits/:listingId" element={<NonprofitPage />} />
												<Route index element={<PhilanthropyFeed />} />
												<Route path="drives" element={<MainDrives />}>
													<Route index element={<Drives />} />
													<Route path="my_drives" element={<MyDrives />} />
													<Route path="favorites" element={<FavoriteDrives />} />
												</Route>
												<Route path="nonprofits" element={<Nonprofits />}>
													<Route index element={<AllNonprofits />} />
													<Route path="add_nonprofit" element={<AddNonprofit />} />
													<Route path="apply_scholarship" element={<GiveScholarship />} />
													<Route path="my_nonprofits" element={<MyNonprofits />} />
												</Route>

												<Route path="volunteer" element={<Volunteer />}>
													<Route index element={<UpcomingVolunteer />} />
													<Route path="my_events" element={<MyEvents />} />
													<Route path="attending" element={<Attending />} />
													<Route path="member_events" element={<MemberEvents />} />
												</Route>

												<Route path="scholarships" element={<Scholarships />} />
												<Route path="impact_stories" element={<ImpactStoryTimeline />} />
												<Route path="impact_stories/add" element={<ImpactSurvey />} />
												<Route path="impact_stories/:storyid" element={<StoryPage />} />
											</Route>
											<Route path="notification" element={<Notification />} />
										</Route>
									</Route>

									<Route path="/dashboard" element={<Dashboard />}>
										<Route index element={<DashboardHome />} />
										<Route path="change_password" element={<AccountDoc />} />
										<Route path="cohatch_documents" element={<CohatchDocuments />} />
										<Route path="cohatch_documents/meeting_event_agreement/:documentId" element={<MEAgreementDoc />} />
										<Route path="cohatch_documents/credit_card_authorization/:documentId" element={<CCAuthorizationDoc />} />
										<Route path="cohatch_documents/membership_terms/:documentId" element={<COhatchMembershipTermsDoc />} />
									</Route>


								</Route>
							</Route>

							<Route element={<Feature excludedPlans={["DOCSIGNEE"]} />}>
								<Route path="/application_file/:applicationId" element={<ApplicationFile />} />
								<Route path="give_terms_and_conditions" element={<GiveScholarshipLandingPage />} />
								<Route path="boost_terms_and_conditions" element={<BoostScholarshipLandingPage />} />
								<Route path="/give_application" element={<ScholarshipApplyPage />} />
								<Route path="/application_success" element={<ApplicationSuccess />} />
								<Route path="/boost_application" element={<ScholarshipApplySUPage />} />
								<Route path="/reapplication" element={<ReapplicationPage />} />
								<Route path="impact" element={<Impact />}>
									<Route index element={<ImpactFeed />} />
									<Route path="overview" element={<Overview />} />
									<Route path="favorites" element={<FavoriteListings />} />
								</Route>
							</Route>
						</Route>

						<Route path="profile/:memberId" element={<ProfileDetail />}></Route>

						{/**Should be accessed by users who are not logged in*/}

						<Route element={<NoAuth />}>
							<Route path="login" element={<Login />} />
							<Route path="signup" element={<Signup />} />
							<Route path="impact_login" element={<ScholarshipLogin />} />
							<Route path="impact_signup" element={<ScholarshipSignup />} />
							{/* <Route path="nonprofit_signup" element={<NonprofitSignup />} /> */}
							<Route path="reset_request" element={<ResetRequest />} />
							<Route path="reset_password" element={<ResetPassword />} />
						</Route>
						<Route path="*" element={<NotFound />} />
					</Route>
				</Route>
			</Routes>
			<ToastContainer limit={4} icon={false} />
		</>
	);
}

export default App;

/* eslint-disable */
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import { useParams } from "react-router-dom";
import getDocDataById from "./getDocDataById";
import { COHATCH_MARKETS, COHATCH_LOCATIONS, COHATCH_MEETING_ROOMS } from "../../constant";
import { jsPDF } from "jspdf";
import config from "../../config";
import updateDocument from "./updateDocument";
import SignatureCanvas from "react-signature-canvas";

function MEAgreementDoc() {
  const {
    userState: { accessToken },
  } = useUserContext();
  const params = useParams();
  const [document, setDocument] = React.useState("");
  const [uploading, setUploading] = React.useState(false);
  const [initials, setInitials] = useState({
    section5: '',
    section6: ''
  });
  const [initialsSaved, setInitialsSaved] = useState({
    section5: false,
    section6: false
  });
  const [isSigned, setIsSigned] = useState(false);
  const [isDocumentSubmitted, setIsDocumentSubmitted] = useState(false);
  const [signature, setSignature] = useState(null); // Stores base64 image
  const [savedSignature, setSavedSignature] = useState(null); // Stores preview URL
  const signaturePadRef = useRef(null);

  const DOCUMENT_TYPES_MAP = {
    MEAgreement: "Meeting & Event Agreement",
    HyggeCoworkingAgreement: "Hygge Coworking Agreement",
    CCAuthorization: "Credit Card Authorization",
    COhatchMembershipTerms: "COhatch Membership Agreement"
  };

  // Helper function to get the display label
  const getDocumentTypeLabel = (documentType) => DOCUMENT_TYPES_MAP[documentType] || documentType;

  const getMarketLabel = (marketValue) => {
    const market = COHATCH_MARKETS?.market?.find(item => item.value === marketValue);
    return market ? market.label : marketValue;
  };

  const getLocationLabel = (marketValue, locationValue) => {
    // Loop through all cities in COHATCH_LOCATIONS
    for (const city of COHATCH_LOCATIONS.cities) {
      if (city[marketValue]) {  // Check if the city has this market
        const market = city[marketValue];
        const locations = market.locations || []; // Get locations for the market
        const location = locations.find(item => item.value === locationValue);

        if (location) {
          return location.label;
        } else {
          console.error(`Location not found for market: ${marketValue}, location: ${locationValue}`);
          return locationValue; // Fallback to the original value
        }
      }
    }
    return marketValue; // Fallback if market is not found
  };

  const getMeetingRoomLabel = (marketValue, locationValue, roomValue) => {
    const rooms = COHATCH_MEETING_ROOMS?.[marketValue]?.[locationValue] || [];
    const room = rooms.find(item => item.value === roomValue);
    return room ? room.label : roomValue;
  };

  const fetchDocument = async () => {
    try {
      const response = await getDocDataById(accessToken, params.documentId);
      if (response.status === "success") {
        const { data } = response;
        setDocument(data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const formatTime = (timeString) => {
    const timeArray = timeString.split(":");
    let hours = parseInt(timeArray[0]);
    const minutes = timeArray[1];
    const period = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour format
    hours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

    // Return formatted time
    return `${hours}:${minutes} ${period}`;
  };


  const termsContent = [
    {
      header: ``,
      content: [
        `I acknowledge COhatch and its affiliates (collectively, “COhatch”) has granted me a revocable license to
          the applicable event / meeting space, whether hosted in-person or virtually. I acknowledge that I will
          follow all instructions and/guidelines issued by COhatch at all times during the duration of my event /
          meeting. COhatch reserves the right to revoke the license with or without cause. In particular, the license
          may be withdrawn to any person whose conduct is deemed to be disorderly, who uses abusive
          language, fails to comply with these terms, any physical or information security policies or any terms
          and/or rules imposed by the event premises or by COhatch, as applicable.`,
      ]
    },
    {
      header: `PAYMENT & CANCELLATION`,
      content: [
        `Full payment is due upon booking and can be made by ACH direct deposit or credit card. A 3.5% credit
          card processing fee is assessed for every credit card transaction. Reservations can be refunded or
          changed up to two weeks prior to the booking. If changes need to be made within 2 weeks of your
          booking, the invoice becomes non-refundable. A full credit will be applied to a future booking.`,

        `In order to book an after-hours social event - COhatch requires a credit card on file with the acceptance
          of said addendum. Failure to comply will result in cancellation.`
      ]
    },
    {
      header: "ACTS OF GOD",
      content: [
        `Sometimes things happen that are entirely out of our control, we get it. In the event of weather
          conditions that pose significant travel risk, COhatch will waive the two-day rescheduling notice, and
          allow for last minute scheduling changes to be made.`,

        `If for any reason COhatch is unable to fulfill its obligations, due to power outages, HVAC issues, or down
          WiFi, COhatch will assume full responsibility and deliver a full refund to the space renter.`
      ]
    },
    {
      header: `CONDUCT EXPECTATIONS INSIDE COHATCH`,
      content: [
        `Client agrees to comply with all applicable city, county, state, and federal laws and shall conduct no
          illegal act on the premises. This is always a drug free and non-smoking facility. Client may not serve
          alcohol to minors on the premises at any time. Client agrees, for everyone’s safety, to ensure alcoholic
          beverages are consumed in a responsible manner. COhatch reserves the right, in its exclusive
          discretion, to expel anyone who is intoxicated or under the influence of alcohol or drugs, or who shall in
          any manner do or participate in any act jeopardizing the rights, use permit, or insurability of COhatch or
          the safety of its staff, guests, or building contents.`
      ]
    },
    {
      header: "CONDUCT & SAFETY VIOLATIONS & FINES",
      content: [
        `Because our space is a shared space, we believe security and cleanliness of our locations is
          imperativeto our services for members, scholars, and non-members alike. We have expectations and
          fines associated with these expectations if they are not met as they are important to keep COhatch in
          working order for everyone who has access. Please read through the following and initial below to
          ensure that you are aware of our expectations and the fines that will be associated if they expectations
          are not adhered to during your meeting or event.`,
        "The following are the responsibilities of the parties involved:",
        [
          `No doors shall be propped open at any time or a fine of $100/per door propped open will be
          assessed. This is to keep everyone safe and avoid any liability or damages that others may incur
          on your behalf.`,

          `All doors shall be locked and secured at the end of your event. If a door is unlocked or not fully
          secured after your event, we will add a $250 fee to your invoice. This is to keep everyone safe
          and avoid any liability or damages that others may incur on your behalf.`,

          `Failing to adhere to the agreed upon rental time frame (showing up too early or leaving too late)
          will result in a fine of $150 or more depending on how early or late you stay at our facilities. All of
          our bookings are made in advance and there may be others who have booked before or after
          your event, so please be sure to consider this when booking your time with us.`,

          `Cleanliness is of the utmost importance to keeping our facility a top-notch meeting and event
          space. We do not provide cleaning services for events, so all clean-up is the responsibility of the
          person who booked the event with us. If we find that the space is overly dirty or damaged, we
          will assess and apply a $100-$500 cleaning fee depending on the condition the space is left in
          after your event.`,

          `Damage to any COhatch property or facilities will be assessed based on the condition, cost of
          the items, or replacement items needed. This fine will be added to a final invoice for your
          meeting or event if incurred.`
        ]
      ]
    },
    {
      header: `ALCOHOL`,
      content: [
        `I provide my assurance that there will be no sale of alcohol during my event (though alcohol is allowed in
        COhatch premises, just not for the purposes of sale). I accept full responsibility for all alcohol
        consumption that occurs during my event and provide my assurance that all attendees will act
        responsibly on the COhatch premises and not disturb COhatch coworkers or office members. I accept
        full responsibility for any damages incurred to COhatch or COhatch affiliated partners because of any
        inebriated meeting / event attendees.`,

        `By signing below, the renter agrees to comply with all applicable city, county, state, and federal laws
        and shall conduct no illegal act on the premises. This is always a drug free and non-smoking facility.
        Renter may not serve alcohol to minors on the premises at any time. Renter agrees, for everyone’s
        safety, to ensure alcoholic beverages are consumed in a responsible manner. COhatch reserves the
        right, in its exclusive discretion, to expel anyone who is intoxicated or under the influence of alcohol or
        drugs, or who shall in any manner do or participate in any act jeopardizing the rights, use permit, or
        insurability of COhatch or the safety of its staff, guests, or building contents.`
      ]
    },

    {
      header: "FACILITIES",
      content: [
        ``,
        [
          `All COhatch owned property must remain in the building.`,

          `We ask that you replace any items that are moved in the space.`,

          `Coffee machines and grinder are NOT available for use. Please don’t attempt to use the machine, as
          it can cause harm and severe burns due to the high temperatures of the water.`,

          `COhatch does not allow any confetti or loose glitter of any kind.`,

          `Command Strips are NOT permitted on the walls, we recommend painters tape only`,

          `Please don't remove any of the furniture from the room that it's in. If furniture is rearranged within
          the room, all furniture must be put back to its original position.`,

          `Client is responsible for the removal of trash at the end of the event.`,

          `During the meeting or event walk-through, the designated trash area will be identified by the
          community manager.`
        ]
      ]
    },
    {
      header: "CLEAN UP PROCEDURES",
      content: [
        `For all after hours events:`,

        `Failure to complete items on the above list may result in a $200 cleaning fee, charged to the card
        on file.`,
        [
          `Turn out all the lights`,
          `Wipe all surfaces`,
          `Load the dishwasher (if necessary)`,
          `Lock the front door`,
          `Turn off the music`,
          `Take out the trash`
        ]
      ]
    },
    {
      header: "ITEMS NOT ALLOWED FOR MEETINGS OR EVENTS",
      content: [
        ``,
        [
          `Glitter or confetti`,
          `Fireworks`,
          `Fog machines`,
          `Crock pots or appliances (without approval from COhatch staff due to electrical compliance)`,
          `No duct tape, double-sided hooks or command strips/hooks applied to COhatch property (walls,
          tables, etc)`,
          `Enclosed or open flame of any kind`
        ]
      ]
    },
    {
      header: `LOAD-IN/LOAD-OUT AND STORAGE`,
      content: [
        `COhatch is not responsible for checking in or handling any items brought into the venue by rental
        companies. All external items must be checked in and signed for by the client or client’s representative.
        All excess material (such as bubble wrap, boxes, hangers, plastic, etc.) created by deliveries must be
        removed and disposed of by the rental company, client, or client’s representative and taken out with
        the rest of your event trash. Storage fees will be applied to any items left overnight or beyond normal
        load-in/load-out times. This also applies to items left post event for shipment out via courier. note: it is
        not the responsibility of COhatch to ensure that pick-ups are scheduled and executed.`,
      ]
    },
    {
      header: `LOST AND FOUND`,
      content: [
        `COhatch takes no responsibility for personal effects and possessions left on premises during or after
        any event. We do, however, maintain a lost and found and will hold recovered items up to 30 days.
        Every attempt will be made to return any recovered item to its rightful owner.`
      ]
    },
    {
      header: "LIABILITY",
      content: [
        `You will be held financially responsible for any property damage, injury, excessive mess, or lost or stolen
        COhatch property during your event/meeting. COhatch is fully equipped with indoor and outdoor
        cameras, so please note that all events are monitored. By signing this agreement, you agree to having
        your card charged for any damages/repairs caused by you or any of your guests. Report any damage
        that occurs during the event to the community manager. Failure to do so will result in a fine.`,
        "The agreement is valid for a period of 12 months.",
        "Termination of this agreement can occur under certain conditions."
      ]
    },
  ];
  // Handle initials input change
  const handleInitialsChange = (section, event) => {
    setInitials((prevState) => ({
      ...prevState,
      [section]: event.target.value
    }));
  };

  const changeInitials = (section) => {
    setInitialsSaved(prevState => ({
      ...prevState,
      [section]: false
    }));
    setInitials(prevState => ({
      ...prevState,
      [section]: ''
    }));
  };

  // Save initials
  const saveInitials = (section) => {
    if (initials[section].trim() !== '') {
      setInitialsSaved((prevState) => ({
        ...prevState,
        [section]: true
      }));
    } else {
      alert("Please enter your initials before saving.");
    }
  };

  const clearSignature = () => {
    signaturePadRef.current.clear();
    setSignature(null);
    setSavedSignature(null);
  };

  const saveSignature = () => {
    if (signaturePadRef.current.isEmpty()) {
      alert("Please provide a signature before saving.");
      return;
    }

    const sigData = signaturePadRef.current.toDataURL("image/png");
    setSignature(sigData);
    setSavedSignature(sigData); // Display preview before submission
  };

  const uploadSignatureToDigitalOcean = async (base64Signature) => {
    try {
      // Convert Base64 to Blob
      const blob = await fetch(base64Signature)
        .then(res => res.blob())
        .catch(err => {
          return null;
        });

      if (!blob) {
        return null;
      }

      // Create a FormData object
      const formData = new FormData();
      formData.append("width", "400"); // Required width
      formData.append("height", "150"); // Required height
      formData.append("folder", "COhatch-Documents/MEAgreement-Documents/Signatures"); // Folder path
      formData.append("image", blob, "signature.png"); // "image" instead of "file"

      // Send the request to the correct API endpoint
      const response = await fetch(`${config.API_BASE_ENDPOINT}/uploads/images`, {
        method: "POST",
        body: formData,
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      if (!response.ok) {
        const responseText = await response.text();
        return null;
      }

      // Get the uploaded image URL from the response
      const data = await response.json();
      return data?.data?.url || data?.data?.small?.url;
    } catch (error) {
      return null;
    }
  };

  const generateAndSavePDF = async () => {
    const doc = new jsPDF();
    doc.text("Agreement Form", 20, 20);

    doc.text(`Meeting Location: ${getMarketLabel(document.meetingLocation.market)} - ${getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)} - ${getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location)}`, 20, 30);
    doc.text(`Meeting Date: ${new Date(document.meetingDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}`, 20, 40);
    doc.text(`Meeting Start Time: ${document.meetingStart}`, 20, 50);
    doc.text(`Meeting End Time: ${document.meetingEnd}`, 20, 60);
    doc.text(`Rate (per Hour): $${document.rate}`, 20, 70);

    let yPosition = 80;
    termsContent.forEach((term) => {
      doc.setFontSize(14);
      doc.text(term.header, 20, yPosition);
      yPosition += 10;

      term.content.forEach((item) => {
        if (Array.isArray(item)) {
          item.forEach((point) => {
            doc.setFontSize(12);
            doc.text(`- ${point}`, 20, yPosition);
            yPosition += 6;
          });
        } else {
          doc.setFontSize(12);
          doc.text(item, 20, yPosition);
          yPosition += 6;
        }
      });
      yPosition += 10;
    });

    doc.text(`Section 5 Initials: ${initials.section5}`, 20, yPosition);
    yPosition += 10;
    doc.text(`Section 6 Initials: ${initials.section6}`, 20, yPosition);
    yPosition += 10;

    // Instead of just generating the PDF, we will now upload it to the server and return the URL.
    const formData = new FormData();
    formData.append("folder", "COhatch-Documents/MEAgreement-Documents/PDFs");
    formData.append("file", doc.output('blob'), "form.pdf");

    const response = await fetch(`${config.API_BASE_ENDPOINT}/uploads/files`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    if (!response.ok) throw new Error("Failed to upload PDF.");

    const data = await response.json();
    return data?.data?.url || data?.data?.small?.url;
  };

  const handleSubmit = async () => {
    try {
      const pdfUrl = await generateAndSavePDF();
      if (!pdfUrl) {
        alert("Failed to upload PDF. Please try again.");
        return;
      }

      const signatureUrl = signature ? await uploadSignatureToDigitalOcean(signature) : null;
      if (!signatureUrl) {
        alert("Failed to upload signature. Please try again.");
        return;
      }

      const payload = {
        initials: [
          { section: "section5", value: initials.section5 },
          { section: "section6", value: initials.section6 },
        ],
        file: pdfUrl,  // PDF stored in Digital Ocean
        signature: signatureUrl, // Signature stored in Digital Ocean
      };

      const response = await updateDocument(accessToken, payload, params.documentId);

      if (response.status === "success") {
        setIsDocumentSubmitted(true);
      } else {
        alert("Something went wrong, please try again.");
      }
    } catch (error) {
      alert("Something went wrong, please try again.");
    }
  };

  const hasErrors = () => {
    return Object.values(initialsSaved).includes(false) || !savedSignature;
  };

  const getFieldErrorStyle = (section) => {
    if (section === 'signature' && !isSigned) {
      return 'border-red-500'; // Error for signature field
    }
    if (initialsSaved[section] === false) {
      return 'border-red-500'; // Error for initials field
    }
    return '';
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10 px-5">
      <div className="bg-white shadow-lg rounded-lg w-full max-w-7xl p-6 mb-10">
        <div className="items-center">
          <div className="">
            <img className="mx-auto mt-4 mb-4 h-auto w-36" src={"https://cohatch-media-assets.nyc3.cdn.digitaloceanspaces.com/static/images/COhatch_hygge%20logos_black.png"} alt=""></img>
          </div>
        </div>
        <h2 className="text-4xl font-semibold mb-6 text-center text-primary-400">{getDocumentTypeLabel(document.documentType)}</h2>
        <div className="space-y-6 mb-6">
          <div className="text-center grid grid-cols-5 gap-2 text-success-800">
            <div>
              <h3 className="font-semibold text-lg">Meeting Location:</h3>
              {document?.meetingLocation ? (
                <p>
                  {getMarketLabel(document.meetingLocation.market)} - {" "}
                  {getLocationLabel(document.meetingLocation.market, document.meetingLocation.location)} - {" "}
                  {getMeetingRoomLabel(document.meetingLocation.market, document.meetingLocation.location, document.meetingLocation.room)}
                </p>
              ) : (
                <p>Loading...</p>
              )}
            </div>

            <div>
              <h3 className="font-semibold text-lg">Meeting Date:</h3>
              <p>{new Date(document.meetingDate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>
            </div>

            <div>
              <h3 className="font-semibold text-lg">Meeting Start Time:</h3>
              <p>{document.meetingStart ? formatTime(document.meetingStart) : 'Invalid Time'}</p>
            </div>

            <div>
              <h3 className="font-semibold text-lg">Meeting End Time:</h3>
              <p>{document.meetingEnd ? formatTime(document.meetingEnd) : 'Invalid Time'}</p>
            </div>


            <div>
              <h3 className="font-semibold text-lg">Rate (per Hour):</h3>
              <p>${document.rate}</p>
            </div>
          </div>

          <div className="space-y-4">
            {termsContent.map((term, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-md shadow-sm">
                <h4 className="font-semibold text-lg">{term.header}</h4>
                {term.content.map((item, idx) => {
                  if (Array.isArray(item)) {
                    return (
                      <ul key={idx} className="list-disc pl-6">
                        {item.map((point, pIdx) => (
                          <li key={pIdx} className="text-gray-600">{point}</li>
                        ))}
                      </ul>
                    );
                  } else {
                    return <p key={idx} className="text-gray-600">{item}</p>;
                  }
                })}

                {/* Display Initials if available */}
                <div>

                  {document?.initials && document.initials.length > 0 ? (
                    <div>

                      {document.initials
                        .filter((initial) => {
                          // Filter initials based on section (section5 or section6) for the correct index
                          return (index === 4 && initial.section === 'section5') || (index === 5 && initial.section === 'section6');
                        })
                        .map((initial, index) => (
                          <p key={index}>
                            <h3 className="font-semibold text-lg">Initials:</h3>
                            Your Initials: {initial.value}
                          </p>
                        ))}
                    </div>

                  ) : (
                    // If no initials are found, display the form to input initials for the specific section (section5 or section6)
                    (index === 4 || index === 5) && !isDocumentSubmitted && (
                      <div className="space-y-4 mt-6">
                        <h4 className="font-semibold text-lg">Initials</h4>
                        <p>Please enter your initials below to confirm your understanding of the terms:</p>
                        <input
                          type="text"
                          value={initials[index === 4 ? 'section5' : 'section6']}
                          onChange={(e) => handleInitialsChange(index === 4 ? 'section5' : 'section6', e)}
                          className={`border p-2 w-full rounded-md ${getFieldErrorStyle(index === 4 ? 'section5' : 'section6')}`}
                          placeholder="Enter your initials"
                        />
                        {!initialsSaved[index === 4 ? 'section5' : 'section6'] && (
                          <button
                            onClick={() => saveInitials(index === 4 ? 'section5' : 'section6')}
                            className="bg-success-800 text-white py-2 px-6 rounded-md hover:bg-success-100 transition duration-300"
                          >
                            Save Initials
                          </button>
                        )}
                        {initialsSaved[index === 4 ? 'section5' : 'section6'] && (
                          <button
                            onClick={() => changeInitials(index === 4 ? 'section5' : 'section6')}
                            className="bg-gray-600 text-white py-2 px-6 rounded-md hover:bg-gray-700 transition duration-300"
                          >
                            Change Initials
                          </button>
                        )}
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="bg-gray-50 p-4 rounded-md shadow-sm">
            <h3 className="text-lg font-semibold text-left">ADDENDUM</h3>
            <p>{document.addendum}</p>
          </div>

          <div className="flex flex-col sm:flex-row sm:gap-8 justify-left items-start"> {/* Aligning items at the top */}
            {/* Signature Canvas Section */}
            <div className="flex-1 sm:w-[55%]">
              {document?.signature ? (
                // If a signature is fetched, display it as an image preview
                <div className="sm:w-[40%] mt-4 sm:mt-0 text-center sm:text-left self-start">
                  <h4 className="text-lg font-semibold mb-2">Signature Preview</h4>
                  <img
                    src={document.signature}
                    alt="Signature Preview"
                    className="border border-gray-500 p-2 max-w-full h-auto mx-auto sm:mx-0"
                  />
                </div>
              ) : (
                // If no signature is fetched, show the signature canvas for the user to draw
                <>
                  <div className="relative mb-4">
                    <SignatureCanvas
                      ref={signaturePadRef}
                      canvasProps={{
                        width: 400,
                        height: 150,
                        className: "border border-gray-400 w-full sm:w-[400px] h-[150px] sm:h-[200px] mx-auto",
                      }}
                    />
                  </div>
                  <div className="flex justify-center gap-4 mb-4">
                    <button
                      onClick={clearSignature}
                      className="bg-danger-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition"
                    >
                      Clear
                    </button>
                    <button
                      onClick={saveSignature}
                      className="bg-primary-400 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition"
                    >
                      Save Signature
                    </button>
                  </div>
                </>
              )}
            </div>

            {/* Signature Preview Section */}
            {savedSignature && (
              <div className="sm:w-[40%] mt-4 sm:mt-0 text-center sm:text-left self-start"> {/* Aligning the preview at the top */}
                <h4 className="text-lg font-semibold mb-2">Signature Preview</h4>
                <img
                  src={savedSignature}
                  alt="Signature Preview"
                  className="border border-gray-500 p-2 max-w-full h-auto mx-auto sm:mx-0"
                />
              </div>
            )}
          </div>
        </div>
        {/* Always Show Submit Button */}
        {!document?.initials?.length || !document?.signature ? (
          <button
            onClick={handleSubmit}
            className={`bg-primary-400 text-white py-2 px-6 rounded-md hover:bg-green-700 transition duration-300 mt-6 ${hasErrors() ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={hasErrors()}
          >
            Submit Document
          </button>
        ) : null}

        {/* Thank You Popup */}
        {isDocumentSubmitted && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg text-center">
              <h2 className="text-2xl font-semibold">Thank You!</h2>
              <p>Your document has been successfully submitted.</p>
              <button
                onClick={() => {
                  setIsDocumentSubmitted(false); // Close the popup
                  window.location.reload(); // Reload the page
                }}
                className="mt-4 bg-red-600 text-white py-2 px-6 rounded-md hover:bg-red-700 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        )}


      </div>
    </div>
  );
};

export default MEAgreementDoc;
